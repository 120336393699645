import { render, staticRenderFns } from "./activityDetail.vue?vue&type=template&id=3ec5e223&scoped=true&"
import script from "./activityDetail.vue?vue&type=script&lang=js&"
export * from "./activityDetail.vue?vue&type=script&lang=js&"
import style0 from "./activityDetail.vue?vue&type=style&index=0&id=3ec5e223&lang=scss&scoped=true&"
import style1 from "./activityDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ec5e223",
  null
  
)

export default component.exports