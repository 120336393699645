<template>
  <div class="activity-detail css-helper">
        <mainBox></mainBox>
    <div class="container">
      <div class="flex top">
        <div class="flex-item detail-img">
          <img class="detail-img-inner" :src="activityData.images" alt="" />
        </div>
        <div class="flex-item flex-1 detail-content">
          <div class="detail-title">{{ activityData.name }}</div>
          <div class="detail-text">
            <div class="detail-text-item">
              活动时间：<span class="detail-text-item-data"
                >{{ activityData.start_time  | time}}至{{
                  activityData.end_time   | time             }}</span
              >
            </div>
            <div class="detail-text-item">
              活动地点：<span class="detail-text-item-data">{{
                activityData.address
              }}</span>
            </div>
            <div class="detail-text-item">
              联系电话：<span class="detail-text-item-data">{{
                activityData.telphone
              }}</span>
            </div>
            <div class="detail-text-item">
              报名状态：<span
                class="detail-text-item-data"
                v-if="activityData.bm_status === '1'"
                >正在报名中
                </span
              ><span v-if="activityData.bm_status === '2'">报名已结束</span>
              <span v-if="activityData.bm_status === '3'">已无名额</span>
            </div>
            <div class="detail-text-item" v-if="activityData.bm_status === '1' || activityData.bm_status === '3'">
              剩余票数：<span class="detail-text-item-data num">{{
                activityData.yupiao
              }}</span>
              <span class="detail-text-item-data">张</span>
            </div>
          </div>
          <div
            class="order-now text-center cursor-pointer"
            v-if="activityData.bm_status === '1'"
            @click="yuyue"
          >
            立即预约
          </div>
          <div
            class="order-now end text-center cursor-pointer"
            v-if="activityData.bm_status === '2' || activityData.bm_status === '3'"
          >
            立即预约
          </div>
          <div class="share flex flex-justify-end">
            <img
              class="star flex-item flex-item-center cursor-pointer"
              src="../assets/activityDetail/star.png"
              alt=""
              v-if="activityData.like_status == 2"
              @click="shoucang"
            />
            <img
              class="star flex-item flex-item-center cursor-pointer"
              src="../assets/activityDetail/staractive.png"
              v-if="activityData.like_status == 1"
              alt=""
              @click="shoucang"
            />
            <span class="flex-item flex-item-center">分享到：</span>
            <img
              class="wechat flex-item flex-item-center cursor-pointer"
              src="../assets/activityDetail/wechat.png"
              alt=""
              @click="haveCode"
            />
            
            <img
              class="flex-item flex-item-center cursor-pointer"
              src="../assets/activityDetail/weibo.png"
              alt=""
              @click="sinlang"
            />
          </div>
        </div>
      </div>
      <div class="details-title text-center">活动详情</div>
      <div class="details">
          <div class="details-text" v-html='this.activityData.details'></div>
      </div>
    </div>
    <myfooter></myfooter>
    <div class="bd_weixin_popup" v-if="share_flag">
      <div
        id="bdshare_weixin_qrcode_dialog_bg"
        class="bd_weixin_popup_bg"
      ></div>
      <div class="bd_weixin_popup_head">
        <span class="share_title">分享到朋友圈</span>

        <div class="bd_weixin_popup_close" @click="share_flag = false">
          <img src="../assets/pop/close.png" alt="" />
        </div>
      </div>
      <div id="qrcode"></div>
      <span class="share_footer">
        打开微信，点击底部的“发现”， <br />
        使用“扫一扫”即可将网页分享至朋友圈。
      </span>
    </div>  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { fetch } from "../api/https.js";
import myfooter from "../components/footer.vue";
import { post } from '../api/https';
import mainBox from "@/components/main.vue";
import moment from 'moment'
export default {
  components: { myfooter,mainBox },
  data() {
    return {
      activityId: "",
      activityData: [],
      share_flag: false,
    };
  },
  filters:{
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
    }
  },
  async mounted() {
    this.activityId = this.$route.query.id;
    let activityData = await fetch("/activity_details", {
      id: this.activityId,
      source: "pc",
      token:localStorage.getItem("token") || ''
    });
    this.activityData = activityData.data;
    // this.qrcode();
    document.title = this.activityData.name
    
  },
  //点击立即预约按钮
methods:{
  async qrcode() {
      await this.$nextTick(() => {
        var qrcode = new QRCode("qrcode", {
          text: window.location.href,
          width: 185,
          height: 185,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    haveCode() {
      this.share_flag = true;
      this.qrcode();
    },
    yuyue(){
      if(localStorage.getItem('token')){
      this.$router.push(`/hotListdeta?id=${this.activityId}`)

      }else{
        this.showPopupMessage('请先登录后预约',false)
        var that = this
        setTimeout(() => {
            that.$router.push("/login");
          }, 2000);
      }
      
    },
    shoucang(){
      if(localStorage.getItem('token')){
        var obj = {
              token:localStorage.getItem('token'),
              source:'pc',
              id:this.activityData.id
        }
          if(this.activityData.like_status == 1){
                  this.activityData.like_status = 2
                  //调用收藏接口 ========== 取消收藏
                  
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
          }else{
                  this.activityData.like_status = 1
                  //调用收藏接口 ========== 取消收藏
                  post('/activity_like',obj).then((res) => {
                    this.showPopupMessage(res.msg,true)
                  })
          }
      }else{
        this.showPopupMessage('请先登录',false)
      }
    },
    subscribeBtn(){},
  sinlang(){
    var href = window.location.href
    let routeUrl = "https://service.weibo.com/share/share.php?title="
     + this.activityData.name +
     "&url="
     +  href +
     "&pic="+this.activityData.images
    window.open(routeUrl, "_blank");
  }
  }
  };
</script>

<style lang="scss" scoped>
.action {
  display: block;
  width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  box-sizing: border-box;
  margin-top: -49px;
  margin-bottom: 50px;
}
.activity-detail {
  background-color: #f8f8f8;
  padding-top: 100px;
  .container {
    max-width: 1400px;
    margin: 0 auto;
    background-color:white;
    margin-bottom:100px;
    .top {
      padding: 40px;
      .detail-img {
        width: 720px;
        height: 480px;
        .detail-img-inner {
          width: 720px;
          height: 480px;
        }
      }
      .detail-content {
        padding: 28px 0 0 44px;
        .detail-title {
          font-size: 28px;
          color: #333333;
          margin-bottom: 45px;
          font-size:'MicrosoftYaHei';
        }
        .detail-text {
          margin-bottom: 70px;
          .detail-text-item {
            color: #999999;
            font-size: 16px;
            margin-bottom: 15px;
            font-size:'MicrosoftYaHei';
            .detail-text-item-data {
              color: #666666;
              &.num {
                color: #ed0000;
              }
            }
          }
        }
      }
      .order-now {
        width: 260px;
        line-height: 60px;
        background-color: #c6863b;
        color: #fff;
        font-size: 22px;
        margin-bottom: 55px;
        &.end {
          background-color: #eeeeee;
          color: #999999 !important;
          cursor: auto;
        }
      }
      .share {
        color: #999999;
        font-size: 14px;
        .star {
          padding-right: 20px;
        }
        .wechat {
          margin-right: 6px;
        }
      }
    }
    .details-title {
      font-size: 24px;
      color: #b87646;
      background: url(../assets/activityDetail/line.png) center center no-repeat;
    }
    .details {
      padding: 56px 100px 96px 100px;
      color: #333333;
      font-size: 16px;
          line-height: 2;

    }
  }
}
.bd_weixin_popup {
  position: fixed;
  left: 50%;
  top: 50%;
  padding: 10px;
  width: 280px !important;
  height: 340px !important;
  background: #ffffff;
  border: solid 1px #d8d8d8;
  z-index: 11001;
  font-size: 12px;
  margin-left: -140px;
  margin-top: -170px;
}
.share_title {
  float: left;
  font-weight: bold;
}

.bd_weixin_popup_head {
  position: relative;
  width: 100%;
  height: 20px;
}
.bd_weixin_popup_close {
  width: 20px;
  height: 20px;
  color: #999;
  text-decoration: none;
  font-size: 16px;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 5px;
}
.bd_weixin_popup_close img {
  width: 9px;
  height: 9px;
  cursor: pointer;
}
#qrcode {
  width: 185px;
  margin: 0 auto;
  padding: 15px 10px;
  
}
#qrcode img {
  margin: 0 auto;
}
.share_footer {
  display: block;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  color: #666;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
<style >
.details-text span{
  color:#666666 !important;
}
</style>